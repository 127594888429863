import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>Erreur 404</h1>
      <p>Cette page n’existe pas.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
